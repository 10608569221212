 .checklist ul {
    list-style: none;
    /* Remove default list style */
    padding: 0;
    gap: 2px;
}

.checklist ul li {
    position: relative;
    padding-left: 30px;
    /* Space for the icon */
    line-height: 1.5;
    display: flex;
    justify-items: center;
    align-items: center;
}

.checklist ul li::before {
    content: '✔';
    /* Unicode character for check mark */
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 10px;
    /* Adjust size as needed */
    color: #00AB7D;
    background-color: #E5FFEC;
    padding: 10px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-bottom: 5px;
    /* Adjust color as needed */
    text-align: center;
    
}
