body {
  scroll-behavior: smooth;
}

.hyperlink a {
  text-decoration: underline !important;
}

@media screen and (max-width: 768px) {
  .ant-pagination-total-text {
    display: block;
    margin-bottom: 0.5rem;
  }
  .custom-button-group {
    display: none;
  }

  .slide-btn {
    margin: 10px;
    width: 30px;
    height: 30px;
    background: #e4e3e5 0% 0% no-repeat padding-box !important;
    opacity: 1;
    border-radius: 50%;
    border: 0;
    /* transition: all .3s; */
    transition: ease-out all 0.3s;
    color: #292d32;
  }

  .parent {
    width: calc(100% - 1rem);
    border-radius: 15px;
    /* width: 100%; */
    height: 121px !important;
  }
  .parent .div {
    display: flex;
    flex-direction: column;
    color: white;
    position: relative;
    bottom: 1.2rem !important;
    left: 0.1rem !important;
    /* font-size: 48px; */
    line-height: 58px;
    font-weight: bold;
    position: absolute;
    font-family: proxima-nova;
    padding: 0 1rem;
  }
}

@media screen and (min-width: 1024px) {
  .custom-button-group {
    text-align: center;
    position: absolute;
    top: 18.5%;
    right: 5%;
  }
  .slide-btn {
    margin: 10px 1rem;
    width: 60px;
    height: 60px;
    background: #e4e3e5 0% 0% no-repeat padding-box !important;
    opacity: 1;
    border-radius: 50%;
    border: 0;
    /* transition: all .3s; */
    transition: ease-out all 0.3s;
    color: #292d32;
  }

  div.adv-content-holder {
    gap: 32px !important;
  }

  div.adv-content-holder div {
    justify-content: start !important;
  }
}

/* clubconnect events */
.radio-toolbar {
  margin-bottom: 30px;
}

/* @media only screen and (max-width: 438px) {
  .flex.ft-btn {
    padding-right:10px!important
  }
} */

.flex.ft-btn {
  margin-top: 70px;
}

@media only screen and (max-width: 531px) and (min-width: 460px) {
  .radio-toolbar label {
    padding: 7px 18px !important;
  }
}

@media only screen and (max-width: 459px) {
  .radio-toolbar label {
    width: 100%;
    text-align: center;
    margin: 10px;
  }

  .canvas {
    width: 100% !important;
  }
}

label.input-label {
  font-size: 12px;
  position: absolute;
  left: 11px;
  top: -14px;
  background: white;
  padding: 0px 6px;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.35);
  pointer-events: auto;
}

h1.canva-sub-title {
  font-size: 20px;
  font-weight: 600;
}

h1.canva-title {
  font-size: 24px;
  font-weight: 600;
}

.canvas.inactive {
  right: -500px;
}

.canvas.active {
  right: 0px;
}

.canvas {
  z-index: 999;
  position: fixed;
  bottom: 0;
  background: white;
  padding: 25px;
  width: 450px;
  height: 100%;
  box-shadow: 0px 3px 12px #00000012;
}

.flex.ft-btn {
  /* background: white;
  position: fixed;
  width: 400px;
  bottom: 15px; */
}

.text-dark-green {
  color: #047832 !important;
}

.head-fixed {
  padding-top: 50px;
  position: fixed;
  width: 91%;
  background-color: #f6f6f6;
  z-index: 99;
}

/* .hidden {
  position: absolute;
  visibility: hidden;
  opacity: 0;
} */
.w-\[645px\] {
  width: 645px;
}

input[type='checkbox'] + label {
  color: #06152b;
  padding: 7px 8px;
}

input[type='checkbox']:checked + label {
  color: #fff;
  background-color: #047832;
  padding: 7px 8px;
  font-style: normal;
  border-radius: 15px;
}

/* 
.radio-toolbar {
  margin: 10px;
} */

.radio-toolbar input[type='radio'] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar label {
  display: inline-block;
  /* background-color: #ddd; */
  padding: 9px 30px;
  font-family: sans-serif, Arial;
  font-size: 14px;
  border: 1px solid #e3e3e4;
  border-radius: 20px;
  margin-right: 10px;
}

.radio-toolbar label:hover {
  background-color: #0478321c;
  border-color: #047832;
}

.radio-toolbar input[type='radio']:focus + label {
  background-color: #0478321c;
  border-color: #047832;
}

.radio-toolbar input[type='radio']:checked + label {
  background-color: #0478321c;
  border-color: #047832;
  color: #047832;
}

img.absolute.icon-text {
  top: 14px;
  left: 5px;
}

button.dark-green {
  background-color: #047832;
  color: white !important;
  border: unset;
}

.drop_box {
  margin: 10px 0;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px dotted #a3a3a3;
  border-radius: 15px;
}

.drop_box h4 {
  font-size: 16px;
  font-weight: 400;
  color: #2e2e2e;
}

.drop_box p {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 12px;
  color: #a3a3a3;
}

span.day-weeks {
  padding: 3px 6px;
  background-color: #047832;
  margin: 5px;
  border-radius: 18px;
  color: white;
}

.loading_screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-screen__content_training {
  animation: shrink 1.5s linear infinite;
}

img.loading_screen_img_training {
  animation: rotating 1.5s linear infinite;
}

.text-justify {
  text-align: justify;
}

.px-10 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}
/* end clubconnect events */

/* for business info */
.w-50 {
  width: 50%;
}
/* end for business info */

@media screen and (max-width: 1024px) {
  .banner-holder {
    height: 450px !important;
  }
}

@media screen and (min-width: 1240px) {
  div.xmas-hero-container {
    height: 904px !important;
  }

  .xmas-hero-text.text-large {
    font-size: 6rem !important;
    padding-top: 3rem !important;
    line-height: 5rem !important;
  }

  .xmas-hero-text .text-sub-large {
    margin-top: 2rem;
    display: block;
    font-size: 7rem !important;
  }

  div.xmas-offer-bg {
    background-image: url('../../assets/images/xmas/offer-bg.png') !important;
    min-height: 379px !important;
    padding: 0px !important;
    gap: 32px !important;
    background-color: transparent !important;
  }
  div.xmas-offer-bg div {
    width: 292px !important;
  }
  .xmas-testimonial:hover {
    margin-top: 2rem !important;
  }

  div.xmas-summary {
    background-image: url('../../assets/images/xmas/xmas-footer.png') !important;
    height: 560px !important;
    background-color: transparent;
  }
}
